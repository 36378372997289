import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// MUI
import {
  makeStyles,
  createStyles,
  useTheme,
  Theme,
} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
const handleDragStart = e => e.preventDefault()

import Layout from '../components/Layout'
import { useStyles } from '../styles/global'

const ProjectPageTemplate = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { allMarkdownRemark } = data
  const { frontmatter } = allMarkdownRemark?.edges[0].node

  return (
    <div>
      <Layout>
        <Box
          mb={['1rem', '2rem']}
          p={['.5rem', '1rem', '2rem']}
          bgcolor={theme.palette.opaque.default}
        >
          <AliceCarousel
            animationDuration={1000}
            animationType="fadeout"
            autoPlay
            autoPlayInterval={3300}
            autoPlayStrategy="none"
            disableButtonsControls
            infinite
            keyboardNavigation
            mouseTracking
            items={frontmatter?.images?.map(
              ({ image }, index) => (
                <GatsbyImage
                  key={index}
                  image={getImage(image)}
                  alt=""
                  loading="eager"
                  onDragStart={handleDragStart}
                  objectFit="cover"
                />
              ),
            )}
          />
        </Box>
        <Grid container justify="space-evenly">
          <Grid
            direction="column"
            item
            container
            xs={12}
            md={6}
          >
            <Box
              mb={['1rem', '2rem']}
              bgcolor={theme.palette.opaque.default}
              display="flex"
              height="fit-content"
            >
              <Box
                display="flex"
                pl={['.5rem']}
                pr={['1rem']}
              >
                <Typography variant="h3">
                  {frontmatter.title}
                </Typography>
              </Box>
            </Box>
            <Box
              ml={['1rem']}
              mr={['1rem']}
              pb={['1rem', '2rem', '3rem']}
            >
              <Typography
                variant="h5"
                className={classes.lightJustify}
              >
                {frontmatter.description}
              </Typography>
            </Box>
          </Grid>
          <Grid
            direction="column"
            item
            container
            xs={12}
            md={5}
          >
            <Box
              borderBottom={`.3rem solid ${theme.palette.primary.main}`}
              width="fit-content"
              fontWeight="fontWeightRegular"
              fontSize="h4.fontSize"
              ml={['1rem']}
              mr={['1rem']}
            >
              <Typography variant="h4">
              Location
                </Typography>
            </Box>
            <Box
              pt={['.5rem']}
              ml={['1rem']}
              mr={['1rem']}
              mb={['2rem']}
            >
              <Typography
                variant="h5"
                className={classes.light}
              >
              {frontmatter.location}
                </Typography>
            </Box>
            {Array.isArray(frontmatter?.details) ? (
              frontmatter.details.map(
                ({ detail }, index) => (
                  <div key={index}>
                    <Box
                      borderBottom={`.3rem solid ${theme.palette.primary.main}`}
                      width="fit-content"
                      ml={['1rem']}
                      mr={['1rem']}
                      pb={['.5rem']}
                    >
                      <Typography variant="h4">
                      {detail.label}
                        </Typography>
                    </Box>
                    <Box
                      pt={['1rem']}
                      ml={['1rem']}
                      mr={['1rem']}
                      mb={['2rem']}
                    >
              <Typography
                variant="h5"
                className={classes.light}
              >
                      {detail.detail}
                </Typography>
                    </Box>
                  </div>
                ),
              )
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allMarkdownRemark(
      filter: { fields: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            title
            details {
              detail {
                detail
                label
              }
            }
            location
            images {
              image {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    aspectRatio: 1.77
                    formats: [AUTO, WEBP, AVIF]
                    quality: 90
                  )
                }
              }
            }
            description
            location
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default ProjectPageTemplate
